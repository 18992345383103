// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	devMode: false,
	clearingEnabled: false,
	domain: 'https://qa.dev.universitycompare.com',
	port: 'http://localhost:4000/assets/json/',
	php_url: 'https://dev.universitycompare.com/php/Web/',
	php_root: 'https://dev.universitycompare.com/php/',
	graphql_url: 'https://dev.universitycompare.com/api/graphql',
	api_url: 'https://dev.universitycompare.com/api/',
	recaptcha_public_key: '6Lfa4AMoAAAAACeWUXt2Kyh1Yw7msV3yTGZdUfDv',
	NEW_RELIC_APP_NAME: 'Test_Test_Test',
	NEW_RELIC_LICENSE_KEY: 'eu01xxa0ea1532e3414080abd7578ba4FFFFNRAL',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
