<h3 class="text-sm font-semibold leading-5 text-gray-300">Quick Links</h3>

<ul class="mt-4 space-y-3 font-semibold text-gray-200">
	<li>
		<a routerLink="/about" class="hover:text-gray-100"> About </a>
	</li>
	<li>
		<a routerLink="/advertise" class="hover:text-gray-100"> Advertise </a>
	</li>
	<li>
		<a routerLink="/contact" class="hover:text-gray-100"> Contact </a>
	</li>
	<li>
		<a (click)="onLoginClick()" routerLink="/login" class="hover:text-gray-100">
			Sign In
		</a>
	</li>

	<li>
		<a (click)="onRegisterClick()" routerLink="/register" class="hover:text-gray-100">
			Register
		</a>
	</li>

	<li>
		<a routerLink="/password" class="hover:text-gray-100"> Password </a>
	</li>
</ul>
