export const environment = {
	production: false,
	devMode: true,
	clearingEnabled: false,
	domain: 'https://dev.universitycompare.com',
	port: 'http://localhost:4002/assets/json/',
	php_url: 'https://dev.universitycompare.com/php/Web/',
	php_root: 'https://dev.universitycompare.com/php/',
	graphql_url: 'https://dev.universitycompare.com/api/graphql',
	api_url: 'https://dev.universitycompare.com/api/',
	recaptcha_public_key: '6Lfa4AMoAAAAACeWUXt2Kyh1Yw7msV3yTGZdUfDv',
};
