<div class="container flex flex-wrap-reverse justify-between gap-4 py-12 text-white">
	<div class="flex flex-wrap-reverse gap-4">
		<p class="row-start-2 xl:text-left">
			&copy;{{ currentYear }} Uni Compare Ltd. All rights reserved.
		</p>
		<ul class="flex flex-row space-x-4">
			<li>
				<a routerLink="/privacy" class="hover:text-gray-100"> Privacy </a>
			</li>
			<li>
				<a routerLink="/terms" class="hover:text-gray-100"> Terms </a>
			</li>
			<li>
				<a routerLink="/advertising-terms" class="hover:text-gray-100">
					Advertising Terms
				</a>
			</li>
			<li>
				<a routerLink="/sitemap" class="hover:text-gray-100"> Sitemap </a>
			</li>
			<li>
				<div
					id="ccpa"
					style="display: none; cursor: pointer"
					class="hover:text-gray-100"
				>
					Do not share my Personal Information.
				</div>
			</li>
		</ul>
	</div>

	<div class="flex space-x-6">
		<a
			href="https://www.tiktok.com/@unicompare?_t=8YAQqjCVEqD&_r=1"
			target="_blank"
			rel="noopener"
			class=""
		>
			<span class="sr-only">TikTok</span>
			<uc-tiktok-svg customStyles="text-white w-6 h-6" />
		</a>
		<a
			href="https://www.linkedin.com/company/unicompare/"
			target="_blank"
			rel="noopener"
		>
			<span class="sr-only">LinkedIn</span>
			<uc-linkedin-footer-svg customStyles="text-white w-6 h-6" />
		</a>
		<a href="https://www.instagram.com/unicompare/" target="_blank" rel="noopener">
			<span class="sr-only">Instagram</span>
			<uc-instagram-outline-svg customStyles="text-white w-6 h-6" />
		</a>
	</div>
</div>
