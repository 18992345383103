<h3 class="text-sm font-semibold leading-5 text-gray-300">Popular Course Options</h3>

<ul class="mt-4 space-y-3 font-semibold text-gray-200">
	<li>
		<a routerLink="/courses/degrees" class="text-gray-300 hover:text-gray-100">
			Bachelor's Degree
		</a>
	</li>

	<li>
		<a
			routerLink="/courses/degrees/masters"
			class="text-gray-300 hover:text-gray-100"
		>
			Master’s Degree Programmes
		</a>
	</li>

	<li>
		<a routerLink="/courses/degrees/phd" class="text-gray-300 hover:text-gray-100">
			PhD Programmes
		</a>
	</li>
	<li>
		<a
			routerLink="/courses/degrees/foundation"
			class="text-gray-300 hover:text-gray-100"
		>
			Foundation Degrees
		</a>
	</li>
	<li>
		<a
			routerLink="/courses/degrees/postgraduate-diploma"
			class="text-gray-300 hover:text-gray-100"
		>
			Postgraduate Diplomas
		</a>
	</li>
	<li>
		<a routerLink="/courses/degrees/pgce" class="text-gray-300 hover:text-gray-100">
			PGCE Courses
		</a>
	</li>
	<li>
		<a routerLink="/courses/degrees/hnc" class="text-gray-300 hover:text-gray-100">
			HNC Courses
		</a>
	</li>
	<li>
		<a routerLink="/courses/degrees/hnd" class="text-gray-300 hover:text-gray-100">
			HND Courses
		</a>
	</li>
	<li>
		<a
			routerLink="/courses/degrees/access-to-higher-education"
			class="text-gray-300 hover:text-gray-100"
		>
			Access to Higher Education
		</a>
	</li>
</ul>
