import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoginGTMService, RegisterGTMService } from '@uc/web/core';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'uc-quick-links',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './quick-links.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickLinksComponent {
	private readonly _componentName = 'Nav';

	constructor(
		private _loginGTMSrv: LoginGTMService,
		private _registerGTMSrv: RegisterGTMService,
	) {}

	// GTM event
	onLoginClick = () => this._loginGTMSrv.loginClick();
	onRegisterClick = () => this._registerGTMSrv.registerClick(this._componentName);
}
