import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { DownloadAppGTMService } from '@uc/web/core';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-app-download-footer-banner',
	standalone: true,
	imports: [NgOptimizedImage, CdnPathPipe],
	templateUrl: './app-download-footer-banner.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppDownloadFooterBannerComponent {
	constructor(private _downloadAppGTMService: DownloadAppGTMService) {}

	onDownloadAppClick = () => this._downloadAppGTMService.downloadAppClick();
}
