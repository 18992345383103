import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'uc-discover-links',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './discover-links.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscoverLinksComponent {}
