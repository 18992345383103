import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'uc-popular-course-links',
	standalone: true,
	imports: [RouterLink],
	templateUrl: './popular-course-links.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopularCourseLinksComponent {}
