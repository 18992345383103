import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QuickLinksComponent } from '../components/quick-links/quick-links.component';
import { DiscoverLinksComponent } from '../components/discover-links/discover-links.component';
import { PopularCourseLinksComponent } from '../components/popular-course-links/popular-course-links.component';
import { AppDownloadFooterBannerComponent } from '../components/app-download-footer-banner/app-download-footer-banner.component';
import { SecondaryFooterComponent } from '../components/secondary-footer/secondary-footer.component';

@Component({
	selector: 'uc-footer',
	standalone: true,
	templateUrl: './footer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		QuickLinksComponent,
		DiscoverLinksComponent,
		PopularCourseLinksComponent,
		AppDownloadFooterBannerComponent,
		SecondaryFooterComponent,
	],
})
export class FooterComponent {}
