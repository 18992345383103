<footer class="bg-uc-blue-950">
	<div class="container py-16">
		<div class="lg:grid lg:grid-cols-3 xl:gap-8">
			<uc-app-download-footer-banner />

			<div
				class="grid grid-cols-2 gap-8 mt-12 sm:grid-cols-7 lg:mt-0 lg:col-span-2"
			>
				<uc-discover-links class="block sm:col-span-2" />

				<uc-popular-course-links class="block row-span-2 sm:col-span-3" />

				<uc-quick-links class="block sm:col-span-2" />
			</div>
		</div>
	</div>
	<uc-secondary-footer class="block bg-blue-900" />
</footer>
